import * as React from 'react';
import clsx from 'clsx';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { Card, CardActionArea, Theme, makeStyles } from '@material-ui/core';
import { Link } from './Link';
import { UseStyles } from '../lib/types/mui';
import { Typography } from './Typography';
import { IconProp, TBaseIconProps, TIconProps } from './FAIcons';
import { TSocialVariants } from '../lib/types/social';
import { getURIToSocial, getSocialPrimaryBrandColor } from '../lib/socialUtils';

type ClassKey = 'maxHeight' | 'contentWrapper' | 'icon';
type StyleProps = { platform: TSocialVariants; };
const useStyles = makeStyles<Theme, StyleProps, ClassKey>((theme: Theme) => ({
    maxHeight: { height: '100%', },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxWidth: '100%',
        height: '100%',
        padding: theme.spacing(3, 5)
    },
    icon(props: StyleProps) {
        return {
            margin: '0 auto',
            padding: theme.spacing(3),
            color: getSocialPrimaryBrandColor(props.platform, theme)
        };
    },
}));

export type TSocialCardP<TIconP extends TIconProps = TBaseIconProps> = {
    platform: TSocialVariants;
    icon: IconProp<TIconP>;
    title?: string;
    handle?: string;
};

export function SocialCard<TIconProps extends TBaseIconProps>(props: TReactFCP<TSocialCardP<TIconProps>>): TReactFCR {
    const { platform, handle, title, icon }: TSocialCardP<TIconProps> = props;
    const to: string = getURIToSocial(platform, handle);
    const styles: UseStyles<ClassKey> = useStyles({ platform });
    const iconClass: string = clsx(styles.icon, icon.props?.className);
    return (
        <Card className={styles.maxHeight}>
            <CardActionArea
                className={styles.maxHeight}
                component={Link}
                variant='external'
                to={to}
                disableColor
                disableUnderline
            >
                <div className={styles.contentWrapper}>
                    <Typography align='center' variant='h6' color='lighter'>{title}</Typography>
                    <icon.Component {...icon.props} className={iconClass} />
                    {handle && <Typography align='center' variant='subtitle2'>{handle}</Typography>}
                </div>
            </CardActionArea>
        </Card>
    );
}
