import { TExternalSocialVariants, TSocialVariants } from './types/social';
import { BLOG_RSS_FEED_PATH } from '../config/routes';
import { Theme } from '@material-ui/core';

export const SOCIAL_DOMAINS: Record<TExternalSocialVariants, string> = {
    keybase: 'keybase.io',
    linkedin: 'linkedin.com',
    twitter: 'twitter.com',
    facebook: 'facebook.com',
    instagram: 'instagram.com',
    youtube: 'youtube.com',
    spotify: 'spotify.com',
    tumblr: 'tumblr.com',
    fitbit: 'fitbit.com',
    reddit: 'reddit.com',
    jawbone: 'jawbone.com',
    runkeeper: 'runkeeper.com',
    github: 'github.com',
    gitlab: 'gitlab.com'
};

export function getURIToSocial(platform: TSocialVariants, handle?: string): string {
    switch (platform) {
        case 'email':
            if (handle === undefined) {
                throw new Error('Email address not specified when building a mailto link');
            }
            return `mailto:${handle}`;
        case 'blogRss':
            return BLOG_RSS_FEED_PATH;
        default:
            return getExternalURLToSocial(platform, handle);
    }
}

export function getExternalURLToSocial(platform: TExternalSocialVariants, handle?: string): string {
    const domain: string = SOCIAL_DOMAINS[platform];
    switch (platform) {
        case 'linkedin':
            return `https://${domain}${handle ? `/in/${handle}` : ''}`;
        case 'twitter':
            return `https://${domain}${handle ? `/${handle}` : ''}`;
        case 'facebook':
            return `https://${domain}${handle ? `/${handle}` : ''}`;
        case 'instagram':
            return `https://${domain}${handle ? `/${handle}` : ''}`;
        case 'youtube':
            return `https://${domain}${handle ? `/channel/${handle}` : ''}`;
        case 'spotify':
            return `https://${handle ? 'open.' : ''}${domain}${handle ? `/user/${handle}` : ''}`;
        case 'github':
            return `https://${domain}${handle ? `/${handle}` : ''}`;
        case 'gitlab':
            return `https://${domain}${handle ? `/${handle}` : ''}`;
        case 'keybase':
            return `https://${domain}${handle ? `/${handle}` : ''}`;
        case 'fitbit':
            return `https://${domain}${handle ? `/user/${handle}` : ''}`;
        case 'tumblr':
            return `https://${handle ? `${handle}.` : ''}${domain}`;
        case 'reddit':
            return `https://${domain}${handle ? `/user/${handle}` : ''}`;
        case 'jawbone':
            // This product has been discontinued
            return `https://${domain}`;
        case 'runkeeper':
            // This product has been largely discontinued
            return `https://${domain}`;
    }
}

export function getSocialPrimaryBrandColor(platform: TSocialVariants, theme: Theme): string {
    switch (platform) {
        case 'linkedin':
            return theme.palette.custom.brands.linkedIn;
        case 'twitter':
            return theme.palette.custom.brands.twitter;
        case 'facebook':
            return theme.palette.custom.brands.facebook;
        case 'instagram':
            return theme.palette.custom.brands.instagram;
        case 'youtube':
            return theme.palette.custom.brands.youtube;
        case 'spotify':
            return theme.palette.custom.brands.spotify;
        case 'github':
            return theme.palette.custom.brands.github;
        case 'gitlab':
            return theme.palette.custom.brands.gitlab;
        case 'keybase':
            return theme.palette.custom.brands.keybase;
        case 'tumblr':
            return theme.palette.custom.brands.tumblr;
        case 'fitbit':
            return theme.palette.custom.brands.fitbit;
        case 'reddit':
            return theme.palette.custom.brands.reddit;
        case 'jawbone':
            return theme.palette.custom.brands.jawbone;
        case 'runkeeper':
            return theme.palette.custom.brands.runkeeper;
        default:
            return 'currentColor';
    }
}
