import * as React from 'react';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { Grid, Theme, makeStyles } from '@material-ui/core';
import { UseStyles } from '../lib/types/mui';
import { SocialCard, TSocialCardP } from './SocialCard';

type ClassKey = 'cardContainer' | 'cardContainerGrid' | 'cardContainerGridItem';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2)
    },
    cardContainerGrid: {
        flexGrow: 1,
        padding: theme.spacing(2)
    },
    cardContainerGridItem: {
        flexBasis: '50%',
        [theme.breakpoints.up('md')]: {
            flexBasis: '33.33333%',
            width: '33.33333%'
        }
    },
}));

export type TSocialCardGridP = {
    cards: TSocialCardP[];
};

export function SocialCardGrid(props: TReactFCP<TSocialCardGridP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    return (
        <div className={styles.cardContainer}>
            <Grid container wrap='wrap' spacing={4} >
                {props.cards.map((c: TSocialCardP) => (
                    <Grid item xs key={c.platform} className={styles.cardContainerGridItem}>
                        <SocialCard {...c} />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
