import * as React from 'react';
import { UseStyles } from '../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { SEO } from '../components/app/SEO';
import { Page } from '../components/app/Page';
import { Typography } from '../components/Typography';
import { graphql } from 'gatsby';
import { SocialCardGrid } from '../components/SocialCardGrid';
import { GQLSocialPageQuery } from '../lib/types/graphql/__generated__/gatsby.gql';
import {
    TwitterIcon, LinkedInIcon, SpotifyIcon, YoutubeIcon, RedditIcon, KeybaseIcon, FacebookIcon, InstagramIcon,
    TumblrIcon, GithubIcon, GitlabIcon, AtIcon, FitbitIcon
} from '../components/FAIcons';

type ClassKey = 'main' | 'title' | 'experienceLinkContainer' | 'basicLink';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    main: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: theme.spacing(4),
        maxWidth: '45em',
        margin: '0 auto'
    },
    title: { marginBottom: theme.spacing(6) },
    experienceLinkContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.baseline.blockMarginBottom
    },
    basicLink: { display: 'inline-block' }
}));

export type TSocialP = {
    data: GQLSocialPageQuery;
};

function Social(props: TReactFCP<TSocialP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { sitePage, site }: GQLSocialPageQuery = props.data;
    const {
        email, linkedin, twitter, facebook, instagram, youtube, spotify, github, gitlab, keybase, tumblr, fitbit, reddit
    } = site!.siteMetadata!.social!;
    return (
        <main className={styles.main}>
            <SEO
                slug={sitePage!.path!}
                title={'Social'}
                seoTitle={'Can you find me?'}
                description={`This Waldo isn't hard to find`}
            />
            <div className={styles.title}>
                <Typography variant='h1' align='center'>Social</Typography>
                <Typography variant='subtitle2' color='lighter' align='center'>Find me on the web</Typography>
            </div>
            <section>
                <SocialCardGrid cards={[
                    {
                        platform: 'linkedin',
                        handle: linkedin!,
                        title: 'LinkedIn',
                        icon: { Component: LinkedInIcon, props: { size: '7x' } }
                    },
                    {
                        platform: 'gitlab',
                        handle: gitlab!,
                        title: 'Gitlab',
                        icon: { Component: GitlabIcon, props: { size: '7x' } }
                    },
                    {
                        platform: 'github',
                        handle: github!,
                        title: 'Github',
                        icon: { Component: GithubIcon, props: { size: '7x' } }
                    },
                    {
                        platform: 'twitter',
                        handle: twitter!,
                        title: 'Twitter',
                        icon: { Component: TwitterIcon, props: { size: '7x' } }
                    },
                    {
                        platform: 'facebook',
                        handle: facebook!,
                        title: 'Facebook',
                        icon: { Component: FacebookIcon, props: { size: '7x' } }
                    },
                    {
                        platform: 'instagram',
                        handle: instagram!,
                        title: 'Instagram',
                        icon: { Component: InstagramIcon, props: { size: '7x' } }
                    },
                    {
                        platform: 'reddit',
                        handle: reddit!,
                        title: 'Reddit',
                        icon: { Component: RedditIcon, props: { size: '7x' } }
                    },
                    {
                        platform: 'spotify',
                        handle: spotify!,
                        title: 'Spotify',
                        icon: { Component: SpotifyIcon, props: { size: '7x' } }
                    },
                    {
                        platform: 'youtube',
                        handle: youtube!,
                        title: 'YouTube',
                        icon: { Component: YoutubeIcon, props: { size: '7x' } }
                    },
                    {
                        platform: 'keybase',
                        handle: keybase!,
                        title: 'Keybase',
                        icon: { Component: KeybaseIcon, props: { size: '7x' } }
                    },
                    {
                        platform: 'fitbit',
                        handle: fitbit!,
                        title: 'Fitbit',
                        icon: { Component: FitbitIcon, props: { size: '7x' } }
                    },
                    // {
                    //     platform: 'tumblr',
                    //     handle: tumblr!,
                    //     title: 'Tumblr',
                    //     icon: { Component: TumblrIcon, props: { size: '7x' } }
                    // },
                    {
                        platform: 'email',
                        handle: email!,
                        title: 'Email',
                        icon: { Component: AtIcon, props: { size: '7x' } }
                    },
                ]} />
            </section>
        </main>
    );
}

export default (props: TReactFCP<TSocialP>): TReactFCR => <Page component={Social} cprops={props} />;

export const query = graphql`
    query SocialPage {
        sitePage(internalComponentName: {eq: "ComponentSocial"}) {
            path
        }
        site {
            siteMetadata {
                social {
                    email
                    linkedin
                    twitter
                    facebook
                    instagram
                    youtube
                    spotify
                    github
                    gitlab
                    keybase
                    tumblr
                    fitbit
                    reddit
                }
            }
        }
    }
`;
